/*
Copyright (C) Computer Handy LLC 2023.  All rights reserved.

All functions to be used by the client need to be exported at the end of this script,
and imported in application.js
*/

/* Globals */
/*
[{"id":0,"word":"whips"},{"id":0,"word":"tuned"},{"id":0,"word":"crowd"},{"id":0,"word":"clone"},{"id":0,"word":"blend"},{"id":0,"word":"maxim"},{"id":0,"word":"risky"},{"id":0,"word":"trend"},{"id":0,"word":"slick"},{"id":0,"word":"false"},{"id":0,"word":"color"},{"id":0,"word":"mired"}]
*/

// const word_bucket = [{"id":0,"word":"whips"},{"id":0,"word":"tuned"},{"id":0,"word":"crowd"},{"id":0,"word":"clone"},{"id":0,"word":"blend"},{"id":0,"word":"maxim"},{"id":0,"word":"risky"},{"id":0,"word":"trend"},{"id":0,"word":"slick"},{"id":0,"word":"false"},{"id":0,"word":"color"},{"id":0,"word":"mired"}];

// var word_to_guess = word_bucket[Math.floor((Math.random() * 11))].word.toUpperCase();
// grab element reference

// get all existing classes
// console.log(guess1EL.classList)

const word_length = 5;
// var word_to_guess = theseed.toUpperCase();
// const word_to_guess = "known".toUpperCase();
// const word_to_guess = "spoof".toUpperCase();

const letter_guesses = Array(word_length).fill("");

const vowelkeys = "wrdle-vowels";
const cconsonantkeys = "wrdle-common-consonants";
const uconsonantkeys = "wrdle-uncommon-consonants";
const server_invalid_word_code = "X"

const guesses_allowed = 6;
const guess_id_prefix = "guess";
const guesses = Array(word_length).fill("");
const PREVGRAYBG = "#6c757d";
const GRAYBG = "#d0cdbc";

var current_guess_row = 0;
var finished = false;
var correct_guess = false;
var api_validate_word_response = "";

var word_id_from_api = "";
var end_game_actual_word = "";

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

// set_guess_box_bgs() run at page load time

function set_guess_box_bgs() {

  var tempguessid;
  var all_lbx;

  for (var lbxs = 0;lbxs < guesses_allowed;lbxs++){
    tempguessid = guess_id_prefix + (lbxs + 1).toString();
    all_lbx = document.getElementsByClassName(tempguessid);

    for (var abox = 0;abox < word_length;abox++) {
      // set_bg_color(all_lbx[abox], GRAYBG);
      set_bg_color(all_lbx[abox],GRAYBG);
    }
  }
}

// xhr.addEventListener("readystatechange", function() {

  xhr.addEventListener("loadend", function() {
  if(this.readyState === 4) {
    console.log("Random word ID is " + this.responseText);
    word_id_from_api = this.responseText;
  }
});

// Get a random word's ID from the app via AJAX:
xhr.open("GET", "/words/new?difficulty=easy");
xhr.send();

function validate_guess(theguess) {
  let vxhr = new XMLHttpRequest();
  vxhr.withCredentials = true;

  vxhr.addEventListener("readystatechange", function() {
    if(this.readyState === 4) {
      console.log("Validation response is " + this.responseText);
    }
  });
}

// TEST START:
// alert("Random word via AJAX was " + gword);
// TEST   END:

// Per-guess word letter counts, reset after each guess:
// set_letter_colors() will decrement the counts as it 
// encounters the letters in the guess, if not 0

// var guessword_letter_counts = [];
// const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

// for (let i = 0; i < alphabet.length; i++) {
//   guessword_letter_counts.push({"theletter": alphabet.charAt(i), "count": 0});
// }

// calculate_letter_counts();

function calculate_letter_counts() {
  // Counts number of letters guessed per guess row
  let aletter = "";

  for (let i = 0; i < alphabet.length; i++) {
    guessword_letter_counts[i].count = 0;
  }
  
  for (let i = 0; i < word_length; i++) {
    var word_guess = letter_guesses.join("").toString();
    aletter = guessword_letter_counts.find(xletter => xletter.theletter === word_to_guess[i]);

    if (aletter != null) {
      aletter.count += 1;
    }
  }
  // Remainder here just for debugging:

  // console.log("Word to guess was " + word_to_guess + ", and letter counts are:");

  // for (let j = 0; j < guessword_letter_counts.length; j++) {
  //   if (guessword_letter_counts[j].count > 0) {
  //     console.log(guessword_letter_counts[j].theletter + " has " + guessword_letter_counts[j].count + " occurances");
  //   }
  // }
}

function setMyKeyDownListener() {
  window.addEventListener(
    "keydown",
    function(event) {checkKeypress(event.key)}
  )
}

function checkKeypress (the_Key) {
  if(the_Key.match(/^[a-zA-Z]$/)){
    enter_letter(null, the_Key);
  }
  if(the_Key == "Enter"){
    check_guess();
  }
  if(the_Key == "Backspace") {
    erase_letter();
  }
}

setMyKeyDownListener();

function clear_array() {
  if (!finished) {
    var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
    var all_letter_boxes = document.getElementsByClassName(guess_id);
    document.querySelector("#resultbox").innerHTML = "";

    for (let i = 0; i < letter_guesses.length; i++) {
      letter_guesses[i] = "";
      all_letter_boxes[i].innerHTML = "";
      all_letter_boxes[i].style.backgroundColor = GRAYBG;
    }
  }
}

function set_bg_color(e, the_color) {
  e.style.backgroundColor = the_color;

  if (the_color == "yellow" || the_color == "red") {
    e.style.color = "black";
  }
  else if (the_color == "green") {
    e.style.color = "white";
  }

  // console.log("Color was set to " + e.style.backgroundColor);
}

function enter_letter(e, the_letter) {
  /* find first empty slot */

  let glen = letter_guesses.length;
  let first_empty = 0;
  var i;

  if (finished) {
    return;
  }

  if (letter_guesses[glen - 1] == "") {
    for (i = 0; i < glen; i++) {
      if (letter_guesses[i] == "") {
        first_empty = i;
        break;
      }
    }

    var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
    var all_letter_boxes = document.getElementsByClassName(guess_id);

    // console.log("first_empty was " + first_empty);

    if (first_empty != glen) {
      letter_guesses[first_empty] = the_letter.toUpperCase();
      all_letter_boxes[first_empty].innerHTML = the_letter.toUpperCase();
    }
  } else {
    console.log("No empty slots left");
  }
}

function check_guess() {
  // This function will become check_guess() and will call finish_check_guess()
  // once the async call is done
  if (letter_guesses[4] != "") {
    var cxhr = new XMLHttpRequest();
    cxhr.withCredentials = true;
    let glen = letter_guesses.length;

    // The following is an async AJAX call to the server:

    cxhr.addEventListener("loadend", function() {
      if(this.readyState === 4) {
        // console.log("check_guess server response for validate was " + this.responseText);
        api_validate_word_response = this.responseText;
        finish_check_guess();
      }
    });

    // Check guess against the server for our word from the app via AJAX:
    // Format of request: /words/id/?type=validate&wordguess=toast
    //   - where id is numeric word ID from DB

    let word_guess = letter_guesses.join("").toString().toLowerCase();
    let checkurl = "/words/" + word_id_from_api + "/?type=validate&wordguess=" + word_guess

    cxhr.open("GET", checkurl);
    cxhr.send();
  }
}

function finish_check_guess() {
  
    let glen = letter_guesses.length;
    let word_guess = letter_guesses.join("").toString().toLowerCase();

    var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
    var all_letter_boxes = document.getElementsByClassName(guess_id);

    // Asynchronous so should be able to continue here ...

    // console.log("check_guess going to check against response: " + api_validate_word_response);

    if (api_validate_word_response != "XXXXX") {
      for (let i = 0; i < glen; i++) {
        let key = "#" + letter_guesses[i];

        // console.log("Class list for letter guess box is: " + all_letter_boxes[i].classList.value);

        if (api_validate_word_response.charAt(i) == "G") {
          set_bg_color(all_letter_boxes[i], "green");
          set_bg_color(document.querySelector(key), "green");
        }
        else if (api_validate_word_response.charAt(i) == "Y") {
          // console.log("letter box color for "+ letter_guesses[i] +" is ==>" + all_letter_boxes[i].style.backgroundColor + "<==");
          if (document.querySelector(key) && document.querySelector(key).style.backgroundColor != "green") {
            // console.log("Setting bg color to yellow");
            set_bg_color(document.querySelector(key), "yellow");
          }
          if (all_letter_boxes[i].style.backgroundColor && all_letter_boxes[i].style.backgroundColor != "green") {
            set_bg_color(all_letter_boxes[i], "yellow");
          }
        }
        else if (api_validate_word_response.charAt(i) == "R") {
          
          if (document.querySelector(key) && document.querySelector(key).style.backgroundColor != "green" &&
          document.querySelector(key).style.backgroundColor != "yellow") {
            set_bg_color(document.querySelector(key), GRAYBG);
          }
          set_bg_color(all_letter_boxes[i], GRAYBG);
        }
      }
    }
    else {
      document.querySelector("#resultbox").style.color = "red";
      document.querySelector("#resultbox").innerHTML = "Not a word: Hit clear and try again!";
      console.log("Word guess was not a valid dictionary word");
    }

    if (api_validate_word_response == "GGGGG") {
      for (let i = 0; i < glen; i++) {
        all_letter_boxes[i].style.backgroundColor = "green";
        document.querySelector("#resultbox").style.color = "green";
        document.querySelector("#resultbox").innerHTML = "CORRECT!";
      }
      finished = true;
      correct_guess = true;
      ending_indication();
    }
    else if (api_validate_word_response != "XXXXX") {
      document.querySelector("#resultbox").style.color = "red";
      document.querySelector("#resultbox").innerHTML = "Incorrect";
      console.log("Word guess was incorrect");
      
      if (current_guess_row == guesses_allowed - 1) {
        finished = true;
        correct_guess = false;
        get_final_word();
        // document.querySelector("#resultbox").innerHTML += ": Word was (TBD)"
      }
    }

    
    // if (correct_guess) {
    //   setTimeout(function() {
    //   blink_keys("red");
    //   }, 500);
      
    //   setTimeout(function() {
    //   blink_keys("green");
    //   }, 1000);
    if (! correct_guess) {
      guesses[current_guess_row] = letter_guesses.join("").toString();

      if (current_guess_row != 5) {
        for (let i = 0; i < letter_guesses.length; i++) {
          letter_guesses[i] = "";
        }
        if (api_validate_word_response != "XXXXX") {
          current_guess_row++;
        }
      } else {
        finished = true;
        
        if (correct_guess == false) {
          set_red_borders();
        }
      }
    }


  // }
}

function get_final_word() {
  var fxhr = new XMLHttpRequest();
  fxhr.withCredentials = true;
  let glen = letter_guesses.length;

  // The following is an async AJAX call to the server:

  fxhr.addEventListener("loadend", function() {
    if(this.readyState === 4) {
      // console.log("check_guess server response for actual word was " + this.responseText);
      end_game_actual_word = this.responseText;
      ending_indication();
    }
  });

  let checkurl = "/words/" + word_id_from_api

  fxhr.open("GET", checkurl);
  fxhr.send();
}

function ending_indication() {
  let alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var resparr = end_game_actual_word.split(',');
  var final_word = "";

  if (correct_guess == false) {
    for (let i = 0; i < resparr.length; i++) {
      final_word += alphabet[resparr[i]];
    }
    document.querySelector("#resultbox").innerHTML += ": Word was " + final_word;
  }

  if (correct_guess) {
    setTimeout(function() {
    blink_keys("red");
    }, 500);
    
    setTimeout(function() {
    blink_keys("green");
    }, 1000);
  } else {
    guesses[current_guess_row] = letter_guesses.join("").toString();
    // calculate_letter_counts();

    if (current_guess_row != 5) {
      for (let i = 0; i < letter_guesses.length; i++) {
        letter_guesses[i] = "";
      }
      current_guess_row++;
    } else {
      finished = true;
      
      if (correct_guess == false) {
        set_red_borders();
      }
    }
  }
}

function check_guess_old() {
  if (letter_guesses[4] != "") {
    var word_guess = letter_guesses.join("").toString();
    let glen = letter_guesses.length;
    // console.log("check_guess word_guess: " + word_guess + ", and word_to_guess: " + word_to_guess);

    validate_guess(word_guess);

    if (word_guess == word_to_guess) {
      var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
      var all_letter_boxes = document.getElementsByClassName(guess_id);

      for (let i = 0; i < glen; i++) {
        all_letter_boxes[i].style.backgroundColor = "green";
        document.querySelector("#resultbox").style.color = "green";
        document.querySelector("#resultbox").innerHTML = "CORRECT!";
      }
      finished = true;
      correct_guess = true;
    } else {
      document.querySelector("#resultbox").style.color = "red";
      document.querySelector("#resultbox").innerHTML = "Incorrect";
      console.log("Word guess was incorrect");
      
      if (current_guess_row == guesses_allowed - 1) {
        finished = true;
        correct_guess = false;
        document.querySelector("#resultbox").innerHTML += ": Word was " + word_to_guess;
      }
    }
    set_letter_colors();
    
    if (correct_guess) {
      setTimeout(function() {
      blink_keys("red");
      }, 500);
      
      setTimeout(function() {
      blink_keys("green");
      }, 1000);
    } else {
      guesses[current_guess_row] = letter_guesses.join("").toString();
      calculate_letter_counts();

      if (current_guess_row != 5) {
        for (let i = 0; i < letter_guesses.length; i++) {
          letter_guesses[i] = "";
        }
        current_guess_row++;
      } else {
        finished = true;
        
        if (correct_guess == false) {
          set_red_borders();
        }
      }
    }
  }
}

function set_letter_colors() {
  let glen = letter_guesses.length;
  var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
  var all_letter_boxes = document.getElementsByClassName(guess_id);
  // var already_guessed_letters = Array(word_length).fill("");
  let aletter = "";

  // console.log("letter_guesses: " + letter_guesses + ", word_to_guess: " + word_to_guess);

  // 2-passes:  First mark those in correct position.
  // Next those left over that are also the right letters.

  for (let i = 0; i < glen; i++) {
    let key = "#" + letter_guesses[i];
    // console.log("Guessed letter in first loop = " + letter_guesses[i]);

    if (letter_guesses[i] == word_to_guess.charAt(i)) {
      set_bg_color(all_letter_boxes[i], "green");
      set_bg_color(document.querySelector(key), "green");
      aletter = guessword_letter_counts.find(xletter => xletter.theletter === letter_guesses[i]);

      if (aletter.count > 0) {
        aletter.count --;
      }
      // console.log("Exact position letter found: " + word_to_guess.charAt(i) + ", count is now " + aletter.count);
    }
  }

  for (let j = 0; j < glen; j++) {
    let key = "#" + letter_guesses[j];
    // console.log("Guessed letter in second loop = " + letter_guesses[j]);
    let letter_color = all_letter_boxes[j].style.backgroundColor;
    aletter = guessword_letter_counts.find(xletter => xletter.theletter === letter_guesses[j]);
    // console.log("Second loop aletter.count = " + aletter.count + ", letter_color = " + letter_color);

    if (word_to_guess.includes(letter_guesses[j]) && aletter.count > 0 && letter_color != "green") {
      
        // console.log("Letter found with count = " + aletter.count);
        // console.log("Setting guess letter to yellow.  The guess_id = " + guess_id + ", key = " + key);
        // console.log("ID of letter guess box = " + all_letter_boxes[j].getAttribute("id"));
        set_bg_color(all_letter_boxes[j], "yellow");
        set_bg_color(document.querySelector(key), "yellow");
        aletter.count --;

      // console.log("Included letter found: " + word_to_guess.charAt(j) + ", count is now " + aletter.count);
    }
    else if(document.querySelector(key).style.backgroundColor != "green") {
      // console.log("Set bg color to gray for " + key);
      // Not green and not yellow so it is a letter guess not in the word:
      set_bg_color(document.querySelector(key), GRAYBG);
    }
    // already_guessed_letters[j] = letter_guesses[j];
    // console.log("already_guessed_letters = " + already_guessed_letters.join("").toString());
  }
  // console.log("set_letter_colors already guessed letters: " + already_guessed_letters.join("").toString());
}

function erase_letter() {
  if (finished) {
    return;
  }

  let glen = letter_guesses.length;
  let first_empty = glen - 1;
  var guess_id = guess_id_prefix + (current_guess_row + 1).toString();
  var all_letter_boxes = document.getElementsByClassName(guess_id);

  if (letter_guesses[0] != "") {
    for (let i = glen - 1; i >= 0; i--) {
      if (letter_guesses[i] != "") {
        letter_guesses[i] = "";
        all_letter_boxes[i].innerHTML = "";
        first_empty = i;
        break;
      }
    }
  } else {
    console.log("Array was empty.  Nothing to erase");
  }
}

function reset_game() {
  for (let i = 0; i < letter_guesses.length; i++) {
    letter_guesses[i] = "";
  }

  for (let i = 0; i < guesses.length; i++) {
    guesses[i] = "";
  }

  current_guess_row = 0;
  finished = false;
  correct_guess = false;

  for (let x = 0; x < guesses_allowed; x++) {
    var guess_id = guess_id_prefix + (x + 1).toString();
    var all_letter_boxes = document.getElementsByClassName(guess_id);
    document.querySelector("#resultbox").innerHTML = "";

    for (let i = 0; i < letter_guesses.length; i++) {
      all_letter_boxes[i].innerHTML = "";
      all_letter_boxes[i].style.backgroundColor = GRAYBG;
      all_letter_boxes[i].style.color = "white";
      all_letter_boxes[i].style.border = "none";
    }
  }
  
  var allvkeys = document.getElementsByClassName(vowelkeys);
  
  if (allvkeys != null) {
    for (let x = 0; x < allvkeys.length; x++) {
      allvkeys[x].style.backgroundColor = "#0d6dfe";
      allvkeys[x].style.color = "white";
    }
  }
    
  var allckeys = document.getElementsByClassName(cconsonantkeys);
  
  if (allckeys != null) {
    for (let x = 0; x < allckeys.length; x++) {
      allckeys[x].style.backgroundColor = "#0d6dfe";
      allckeys[x].style.color = "white";
    }
  }
    
  var allukeys = document.getElementsByClassName(uconsonantkeys);
  
  if (allukeys != null) {
    for (let x = 0; x < allukeys.length; x++) {
      allukeys[x].style.backgroundColor = "#0d6dfe";
      allukeys[x].style.color = "white";
    }
  }

  /* Temp solution: */
  window.location.reload();
}

function blink_keys(the_color) {
  let glen = letter_guesses.length;
  let the_row = current_guess_row + 1;
  
  if (current_guess_row > guesses_allowed) {
    the_row = guesses_allowed;
  }
  var guess_id = guess_id_prefix + (the_row).toString();
  var all_letter_boxes = document.getElementsByClassName(guess_id);

  for (let i = 0; i < glen; i++) {
    set_bg_color(all_letter_boxes[i], the_color);
  } 
}

function set_red_borders() {
  let glen = letter_guesses.length;
  var guess_id = guess_id_prefix + (guesses_allowed).toString();
  var all_letter_boxes = document.getElementsByClassName(guess_id);
  
  for (let i = 0; i < glen; i++) {
    all_letter_boxes[i].style.border = "3px solid red";
  } 
}

// function toggle_instructions() {
//   var thebox = document.querySelector("#instructionbox");

//   console.log("Entered toggle_instructions().  Classlist: " + thebox.classList);

//   if (thebox.classList.contains('gawhidden')) {
//     thebox.classList.remove('gawhidden');
    // thebox.classList.add('gawreveal');
  // }
  // else {
    // thebox.classList.remove('gawreveal');
    // thebox.classList.add('gawhidden');
  // }

  // var theheading = document.querySelector("#instheader");

  // if (theheading.classList.contains('gawhidden')) {
  //   theheading.classList.remove('gawhidden');
  //   theheading.classList.add('gawreveal');
  // }
  // else {
  //   theheading.classList.remove('gawreveal');
  //   theheading.classList.add('gawhidden');
  // }
// }

export { clear_array, enter_letter, set_bg_color, check_guess, finish_check_guess, erase_letter, reset_game, blink_keys, set_guess_box_bgs };
