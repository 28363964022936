// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start() -- UJS now part of Rails as of 5.1.0
// require("turbolinks").start() -- Disabling for now until needed for additional Webpages

require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
// window.guessaword = require("guessaword")

import "bootstrap"
import "stylesheets/application"
// import "../packs/guessaword"

import {enter_letter, erase_letter, check_guess, clear_array, reset_game, set_guess_box_bgs } from 'guessaword';
window.reset_game = reset_game;
window.enter_letter = enter_letter;
window.check_guess = check_guess;
window.clear_array = clear_array;
window.erase_letter = erase_letter;
window.set_guess_box_bgs = set_guess_box_bgs;

// Alternate way of importing bootstrap elements:

// import "bootstrap/dist/js/bootstrap"
// import "bootstrap/dist/css/bootstrap"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

